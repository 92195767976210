import {
  TextField, Dialog, Checkbox, FormControlLabel, Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { User } from 'lib/types/users';
import { useState, ChangeEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { userIsProOrAdmin, userIsTeamMember } from 'lib/helpers';

interface Props {
  profileUser: User;
  onCancel: () => void;
}

const defaultSubject = 'I saw your profile on Gondola';

export const ContactModal = ({
  profileUser, onCancel,
}: Props) => {
  const API = useApi();
  const currentUser = useCurrentUser();

  const [subject, setSubject] = useState(defaultSubject);
  const [body, setBody] = useState('');
  const [ccEmail, setCcEmail] = useState(true);

  const disableSubject = !userIsProOrAdmin(currentUser) && !userIsTeamMember(currentUser);

  const { enqueueSnackbar } = useSnackbar();

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    setBody(e.target.value);
  };

  const sendEmail = async () => {
    try {
      await API.sendDirectMessage(profileUser.id, subject, body, ccEmail);
      enqueueSnackbar(`Sent your message to ${profileUser.name}`, {
        variant: 'success',
      });
      onCancel();
    } catch (e: any) {
      enqueueSnackbar(`Failed to send your message to ${profileUser.name}: ${e}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <div className="p-6">
        <h1 className="text-lg font-bold mb-4">
          Send a message to
          {' '}
          {profileUser.name}
        </h1>
        <div>
          <div className="mb-4 flex items-center gap-2">
            <TextField
              variant="outlined"
              name="subject"
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              disabled={disableSubject}
            />
            {disableSubject && (
              <Tooltip title="Upgrade your account to customize the subject of your messages">
                <Info color="secondary" />
              </Tooltip>
            )}
          </div>
          <div className="mb-4">
            <TextField
              variant="outlined"
              name="body"
              label="Message"
              fullWidth
              multiline
              onChange={change}
              minRows={3}
            />
          </div>
          <div className="mb-4">
            <FormControlLabel
              control={(
                <Checkbox
                  id="send-copy"
                  name="send-copy"
                  checked={ccEmail}
                  onChange={() => setCcEmail(!ccEmail)}
                />
              )}
              label="Send me a copy"
            />
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <button type="button" className="btn-primary-outlined" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={sendEmail}
            disabled={!body.length}
          >
            Send
          </button>
        </div>
      </div>
    </Dialog>
  );
};
